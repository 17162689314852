import React from 'react'
import PropTypes from 'prop-types'
import { OneColumnBanner as BannerOneColumnComponent } from '@cwp/amp-microsites/build/components.es.js'
import { APIENDPOINT, getCWPEnvironmentForURL } from '../../../../common/utils/common-utils'

const BannerOneColumn = props => {
  const env = getCWPEnvironmentForURL(window.location.href)
  const galleryPath = APIENDPOINT(env).baseGallery
  const svg = `${galleryPath}content/gallery/ampmicrosites/brand-icons`
  let facet
  const hasFacet = props?.content?.bgColor?.includes('facet')
  if (hasFacet) {
    facet = `${galleryPath}content/gallery/ampmicrosites/facets/banner-facet-${
      props?.content?.bgColor?.replace('-facet', '') || 'midnightblue'
    }.svg`
  }
  const newContent = {
    ...props.content,
    svgUrl: svg,
    bgColor: facet ? facet : props.content.bgColor ? props.content.bgColor : 'u-bgColorBrandMidnight'
  }

  return <BannerOneColumnComponent content={{ ...newContent }} />
}
BannerOneColumn.propTypes = {
  content: PropTypes.object,
}
export default BannerOneColumn