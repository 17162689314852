import React from 'react'
import { APIENDPOINT, getCWPEnvironmentForURL } from '../../../../common/utils/common-utils'
import { Alert as AlertComponent } from '@cwp/amp-microsites/build/components.es.js'

const Alert = props => {
  const { content } = props
  const env = getCWPEnvironmentForURL(window.location.href)
  return (
    <>
      <AlertComponent
        content={content}
        svgUrl={`${APIENDPOINT(env).baseGallery}content/gallery/ampmicrosites/brand-icons`}
      />
    </>
  )
}

export default Alert
